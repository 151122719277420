.validation-code-input {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
  
  .validation-code-input input {
    width: 2em; /* Adjust the width to make it almost square */
    height: 2em; /* Adjust the height to make it almost square */
    text-align: center;
    font-size: 1.2em; /* Adjust the font size for the digit */
    border: 1px solid #ccc; /* Add a border */
    border-radius: 10px; /* Rounded corners */
    margin: 0 5px; /* Add spacing between inputs */

    color: #1A857A;
  }
  

/* Style the input when it's disabled */
.validation-code-input input:disabled {
  background-color: #ccc; /* Change the background color to a grayed-out color */
  color: #777; /* Change the text color */
  cursor: not-allowed; /* Change the cursor to indicate it's not editable */
  /* Add any additional styles for the disabled state here */
}



  /* Remove default input styling */
  .validation-code-input input:focus {
    outline: none;
    border-color: #1A857A;
    caret-color: transparent;
  }
  
