$theme1: #1A857A;
$theme2: #27C18B;
$theme3: #18CE9C;
$bg1: #081D1E;
$bgSoft: #eafeff;
$bgSoft400: #a8d8f9;

.fmd-header{
    background-color: $bg1;
    height: 60px;
    display: flex;
    padding: 50px 25px;

    &-logo{
        width: 170px;
        align-self: center;

    }
}