main {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
}

main h1 {
  margin: 10px 0px;
  font-weight: 400;
  font-size: 1.7rem;
}



.input-container {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 100%;
}


.email-input {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .confirm-form p:first-child {
    margin-top: 0px;
  }

  main p:first-of-type {
    margin-top: 0px;
  }


}

@media screen and (min-width: 768px) {
  .page-title {
    font-size: 2em;
  }

}


@media screen and (min-width: 768px) {
  .email-input {
    min-width: 250px;
    flex-grow: 1;
  }
}

.custom-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-input:focus {
  border-color: #007bff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.delete-account-button {
  background-color: #ff5252;
  /* Red color, you can use your theme color */
  color: #fff;
  /* White text color */
  padding: 15px 10px;
  /* Adjust padding to your liking */
  border: none;
  border-radius: 5px;

  cursor: pointer;
  font-size: 16px;
  margin-left: -2px;
  width: 212px;
}

.delete-account-button:hover {
  background-color: #ff0000;
  /* Darker red on hover */
}

.delete-account-button:disabled {
  background-color: #ccc; 
  color: #666; 
  cursor: not-allowed; 
}

.primary-button {
  background-color: #007bff;
  /* Blue shade, you can use your theme color */
  color: #fff;
  /* White text color */
  padding: 10px 20px;
  /* Adjust padding to your liking */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 272px;
}

.primary-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
}

.primary-button:disabled {
  background-color: #ccc;
  /* Change the background color to a grayed-out color */
  color: #777;
  /* Change the text color */
  cursor: not-allowed;
  /* Change the cursor to indicate it's not clickable */
  /* Add any additional styles for the disabled state here */
}


.form-container {
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 46px;
  height: 46px;
  border: 5px solid #4b4b4b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-sm {
  width: 23px;
  height: 23px;
  border: 3px solid #4b4b4b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 40px 0px;

}

.note {
  background-color: #fefce8;
  /* Light gray background color */
  border: 1px solid #ddd;
  /* Light gray border */
  padding: 10px;
  /* Adjust padding to your liking */
  margin: 10px 0;
  /* Add spacing around the note paragraph */
  color: #8b5304;
  /* Text color */
  font-size: 14px;
  /* Adjust font size as needed */
  border-radius: 5px;
  /* Rounded corners */
}

/* You can also add additional styles for emphasis, such as italic text or a different font color. */
.note em {
  font-style: italic;
}

/* Example: Make the text italic. */
.note em {
  font-style: italic;
}

/* Example: Make the text blue. */
.note em {
  color: blue;
}


.confirm-form {
  max-width: 600px;
}

.note svg {
  width: 20px;
  color: #facc15;
  margin-top: 5px;
  height: 1.25rem;
  margin-right: 3px;
  margin-bottom: -5px;

}


/* Styles for the modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Adjust z-index to ensure it's on top of other content */
}

/* Styles for the modal */
.modal {
  max-width: 500px;
  background-color: #fff;
  /* White background for the modal */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1001;
  /* Should be higher than the backdrop's z-index */
}


.email-message {
  margin-top: 32px;
}



@media screen and (max-width: 768px) {


  .form-container {
    width: 100%;
  }

  form.deletion-form {
    width: 100%;
    margin: 0px 10px;
  }




  .delete-account-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px 15px;
  }

  .email-message {
    margin-top: 8px;
  }

  .fmd-header{
    padding: 40px 25px !important;
  }

  .fmd-header-logo {
    width: 150px !important;
  }
  

}

.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spin {
    -moz-appearance: textfield;
}



.text-center {
  text-align: center;
}

.text-error{
  color: #c53232; 
  font-weight: 500;
}

.captcha-container{
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}